.userListTableHeaderSection {
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    align-items: center;
    gap: 16px;
}

.filterContainerSection {
    width: 50%;
}

.templateListTableHeaderSection {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.loginPage .MuiBox-root {
    padding: 27px;
}

.error-input .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}

.userListHeadLine {
    font-size: 22px;
    font-weight: 800;
    display: flex;
    align-items: center;
}

.listAddBtn {
    padding: 0px 10px 0px 0px;
}

.mappingSplitLine {
    border: 1px solid;
}

.dividerSpacing {
    padding: 24px 0px 0px 0px;
}

//TEMPLATE RELATED CSS

.templateDetailsPage .MuiFormControl-root {
    margin: 0px !important;
}

.templateFormContainer {
    padding: 20px;
}

.templateFormHeadLine {
    font-size: medium;
    margin-bottom: 10px;
    font-weight: 500;
}

.templateButtonContainer {
    display: flex;
    justify-content: space-between;
}

.templateFormOption {
    padding: 20px;
}

.dynamicTemplateFieldContainer {
    padding: 10px 60px;
}

.empalteActivePreviewBtnContainer {
    display: flex;
    gap: 16px;
}

.MuiDialog-paper {
    padding: unset !important;
    width: 30%;
}

.MuiDialog-paperFullScreen {
    width: 100% !important;
}

.popupHeadLine {
    font-size: 20px;
    font-weight: 1000;
    padding: 0px 0px 6px 14px;
}

.textarea {
    width: 100%;
}

#userDetailsForm .MuiFormControl-root {
    margin: unset;
}

//transfer list
.css-1ouc1ue-MuiContainer-root {
    margin-top: 70px !important;
    background-color: #f0f8ff;
}

.sub-heading {
    font-size: 1rem;
}

.sub-heading2 {
    font-size: 1rem;
    font-weight: bold;
}

.heading {
    font-size: 1.5rem;
    color: #5e35b1;
}

.nxtBtn {
    width: 100%;
}

.btnalign {
    align-items: center;
    display: flex;
    justify-content: right;
}

.aprbtnalin {
    align-items: left;
    display: flex;
    justify-content: left;
}

.backbtnalign {
    align-items: center;
    display: flex;
    justify-content: left;
}

.brdcrmbmenu {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
}

.workflowForm {
    pointer-events: none;
    /* Disable form interaction */
    background-color: #dddddd;
    /* Transparent background color */
}

.workflowContainer .workflowFormContainer .fldspacing {
    max-height: unset !important;
    padding-bottom: 10px !important;
}

.workflowFormContainer .tooltipAvailable {
    margin-top: unset;
}

.workflowFormContainer .tooltipNotAvailable {
    margin-top: 10px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.templateContainer {
    gap: 10px;
    margin: auto;
}

.span-title {
    align-items: center;
}

.col-1 {
    padding: 20px;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.col-2 {
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sm-card {
    border: solid;
    border-width: 1px;
    margin-bottom: 5px;
}

.inner-continer {
    padding: 10px;
}

.field-set {
    display: flex;
    flex-direction: column;
}

.crt-field {
    margin-top: 5px;
    padding: 2px;
}

.btn {
    margin-right: 5px;
}

.templateValidationContainer {
    padding-left: 16px;
}

.templateTopBtnContainer {
    display: flex;
    justify-content: space-between;
}

.fieldItemsContainer {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.templateField {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.templateFieldHeadLine {
    padding: 10px;
}

//End user project flow related css
.projectHeadPopUp .MuiFormControl-root {
    margin: unset;
}

.removeMapping svg {
    color: red;
}

.budget-form .MuiAutocomplete-root {
    padding-top: 20px;
}

.placeholder-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fade-in {
    animation: fade-in-animation 1s ease-in;
}

@keyframes fade-in-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.placeholder img {
    animation: fade-in 1s ease-in-out;
    max-width: 100%;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.timeline {
    transform: rotate(90deg);
}

.timelineContentContainer {
    text-align: left;
}

.timelineContent {
    display: inline-block;
    transform: rotate(-90deg);
    text-align: center;
    min-width: 50;
}

.timelineIcon {
    transform: rotate(-90deg);
    z-index: 20;
}

.submitbtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    height: 36.5px;
    width: 64px;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #2196f3;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 8px;
}

.addbtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    height: 36.5px;
    width: 64px;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #2196f3;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 8px;
}

.removebtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    height: 36.5px;
    width: 64px;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: red;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 8px;
}

// .MuiButton-root[disabled] {
//     /* Set the desired color when the button is disabled */
//     color: #ffffff; /* Replace with your desired color */
//     background-color: #673ab7; /* Replace with your desired color */
//   }
.dragIconAndHeadContainer {
    display: flex;
}

.ConfigureBackBtn {
    position: relative;
    bottom: 15px;
    right: 19px;
}

.fullDialogBoxContainer {
    float: right;
}

.templateNoRecordFound {
    display: flex;
    justify-content: center;
    padding: 203px 0px;
}

.templateBtnContainer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding-bottom: 10px;

}

#templateActiveBtn {
    margin-right: 10px;
    float: right;
    padding: 6px 16px !important;
}

.minMaxContainer {
    display: flex;
    gap: 10px;
}

.dragCardIconContainer {
    padding: 11px 0px;
}

.removeMargin .MuiFormControl-root {
    margin: 0px 0px !important;
}

// drag and drop related css
.Board,
.draggableInputFieldContainer {
    min-height: 500px;
    border-radius: 5px;
    padding: 20px;
}

.droppableInputField {
    border: 1px solid;
    border-radius: 15px;
    margin: 6px;
    display: flex;
    justify-content: space-between;
    padding: 6px 1px;
}

.dragabbleInputFields {
    display: flex;
    /* Use flexbox */
    align-items: center;
    /* Vertically center the content */
    margin: 6px 0px;
    border: 1px solid;
    border-radius: 7px;
    padding: 4px;
    gap: 10px;
    cursor: pointer;
}

.drabbapleBtnContainer {
    display: flex;
    gap: 10px;
    padding: 8px;
}

.dragging {
    opacity: 0.5;
    transform: scale(1.1);
    transition: opacity 0.3s, transform 0.3s;
}

.dragIcon {
    padding: 11px 0px;
}

.dragCardTypeLabelContainer {
    padding: 5px;
}

.draggableInputField {
    transition: transform 0.3s;
}

.templateFieldListContainer {
    padding: 12px;
    border: 1.5px solid;
    border-radius: 5px;
    min-height: 550px;
}

.FieldAndConfigContainer {
    border: 1.5px solid;
    border-radius: 5px;
    min-height: 550px;
}

/* Apply sticky styles when 'isSticky' class is present */
.FieldAndConfigContainer.isSticky {
    position: fixed;
    top: 100px;
    /* Adjust the value to set the sticky position relative to the viewport */
    width: calc(25% - 10px);
    /* Adjust the width as needed */
}

.workFlowTypeField {
    padding-top: 35px !important;
}

.mappingNoRecordFound {
    display: flex;
    justify-content: center;
}

.clearBtn {
    margin-top: 24px;
    margin-bottom: 24px;
}

// version table ui chages
.formContainer .formTitle {
    font-size: 20px;
    font-weight: 700;
    color: #36454f;
}

.formContainer .formType {
    font-size: 15px;
    color: gray;
}

.versionTable tr.formHeaderRowSection {
    border-top: 1px solid #d3d3d3;
}

.versionTable td.MuiTableCell-root {
    border-bottom: unset;
}

//otp timer in login  page

/* Style the OTP container */
.otpContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Style the timer and resend button container */
.timerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.timerContainer p {
    font-size: 14px;
    margin: 0;
}

/* Style the resend link button */
.resendLink {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.resendLink:disabled {
    color: #999;
    /* Change to your desired disabled color */
    cursor: not-allowed;
    text-decoration: none;
}

.MasterListSection .fldspacing {
    border-bottom: unset !important;
}

.tempSaveContainer {
    display: flex;
    float: right;
    padding-top: 30px;
    gap: 10px;
}

.LoginSection .fldspacing {
    padding-bottom: unset !important;
}

// workflow =>sterpper button
.stepperButtonContainer {
    justify-content: center;
}

.versionDetailsContainer {
    padding-bottom: 30px;
}

.rangeRatingContainer {
    display: flex;
    gap: 10px;
}

.ratingLabel div {
    text-align: center;
    font-size: 18px;
    margin-left: unset !important;
    color: #2196f3;
}

.tabTextTransformation {
    text-transform: none !important;
}

.versionStatusSection {
    display: flex;
    justify-content: flex-end;
}

.workflowVersionDetailsContainer {
    width: 100%;
}

.workflowComponentContainer {
    padding: 10px 30px;
}

.workflowBasicDetailsContainer,
.WorkFlow {
    border: 1px solid #ced4da;
}

.workFlowVersionDetailsLable {
    margin-right: 16px;
    font-weight: bold;
    color: #868e96;
}

.chipStyle {
    background-color: #00e676ac;
    color: #00e676;
}

.tableCellOverlap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-with-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
}

// tooltip
.fieldToolTipSection {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 10px;
    z-index: 100;
}

.themeOneToolTip {
    border: 1px solid #2196f3;
    background-color: white;
    color: #2196f3;
}

.themeTwoToolTip {
    border: 1px solid #607d8b;
    background-color: white;
    color: #607d8b;
}

.themeThreeToolTip {
    border: 1px solid #203461;
    background-color: white;
    color: #203461;
}

.themeFourToolTip {
    border: 1px solid #16595a;
    background-color: white;
    color: #16595a;
}

.themeFiveToolTip {
    border: 1px solid #173e43;
    background-color: white;
    color: #173e43;
}

.themeSixToolTip {
    border: 1px solid #0a2342;
    background-color: white;
    color: #0a2342;
}

.themeSevenToolTip {
    border: 1px solid #3f51b5;
    background-color: white;
    color: #3f51b5;
}

// .customeToolTipMessageSection {
//     border: 1px solid #2196f3;
//     background-color: white;
//     color: #2196f3;
// }




.fieldToolTipSectionText {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 10px;
    z-index: 100;
}

.centerAllign {
    display: flex;
    justify-content: center;
}

.addbtnUserMap {
    margin-top: 25px;
}

.snoWidth {
    width: 5% !important;
}

//Basic Table Cell Sizes

// .cellSize_userStatus {
//     width: 116px;
// }

// .cellSize_Sno {
//     width: 10%;
// }

// .cellSize_name {
//     min-width: 40%;
// }

// .cellSize_sequence,
// .cellSize_headType,
// .cellSize_status,
// .cellSize_action {
//     width: 20%;
// }


// .cellSize_userName {
//     width: 30%;
// }

// .cellSize_userType,
// .cellSize_userStatus,
// .cellSize_refNumber {
//     min-width: 20%;
// }

.forgetPasswordContainerSection {
    min-height: 25px;
}

.loginPage {
    min-height: 289px;
}

.loginFieldContainer {
    min-height: 100px;
}

input::-ms-reveal {
    display: none;
}

.cell-with-nextline {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
}

.projectHoldBtn {
    color: #fcc419;
    border-color: #f08c00;
}

.projectCommentRichTectEditor .ql-editor {
    height: 100px;
}

.investigatorTableBody::-webkit-scrollbar {
    width: 5px;
}

.investigatorTableBody::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.investigatorTableBody::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.flexend {
    display: flex;
    justify-content: flex-end;
}

.notAndErrorSpacing {
    position: relative;
    bottom: 11px;
}

.accordion-title {
    font-weight: bold;
}

.accordion-details {
    padding: 5px 0px 10px 30px;
}

.container {
    width: 100%;
    padding: 0 20px;
    /* Adjust the padding as needed */
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap to the next line if needed */
    justify-content: space-between;
    /* Align items evenly along the main axis */
    align-items: flex-start;
    /* Align items at the start of the cross axis */
}

.flex-item {
    flex: 0 0 calc(50% - 10px);
    /* Adjust the width of each item as needed */
    margin-bottom: 20px;
    /* Adjust the margin between items as needed */
    height: 70px;
    /* Set the maximum height for each item */

}

.flex-item.timeToRemaind {
    margin-top: '50px' !important;
}



/* chat related styles  */
.chat-input-wrapper {
    border: 2px solid #ccc;
    position: absolute;
    width: 82%;
    bottom: 10px;
    background-color: white;
}

.send-button-wrapper {
    display: flex;
    justify-content: flex-end;
    background-color: #683aba;
    border-radius: 20px;
}


/* Chat container */
.chat-container {
    padding: 0px 15px;
    height: 100%;
}

/* Chat messages */
.chat-messages {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    height: 75%;
}

/* Individual message */
.message {
    display: flex;
    margin-bottom: 10px;
}
.message img{
    width: 100%;
}

/* Message content */
.message-content {
    position: relative;
    width: 80%;
    padding: 10px;
    border-radius: 8px;
    /* Example color for message bubbles */
}

/* Timestamp */
.timestamp {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
}

/* Reply message */
.replied-message {
    margin-top: 5px;
    padding: 10px 5px 8px 15px;
    background-color: #e9ecef;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: -5px 0 5px -5px rgba(207, 30, 30, 0.5);
    /* Left vertical shadow */
}

.text-container {
    max-width: 100%;
    /* Set the maximum width of the text container */
    overflow: hidden;
    /* Hide any content that overflows the container */
    text-overflow: ellipsis;
    /* Add ellipsis (...) for text overflow */
    white-space: break-spaces;
    /* Prevent text from wrapping */
}

/* Edit controls */
.edit-controls {
    position: absolute;
    bottom: -25px;
    right: 0;
}

.edit-controls button {
    margin-left: 5px;
}

.replied-message-container {
    background-color: #f5f5f5;
    /* Light gray background color */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
    opacity: 0;
    /* Initially hide the container */
    transition: opacity 0.3s ease-in-out;
    /* Add a transition for opacity */
}

.replied-message-container.show {
    opacity: 1;
    /* Show the container with fade-in effect */
}

.message-content.highlighted {
    background-color: #96f2d7 !important;
}

.replay-content-section {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.replied-message-content {
    display: flex;
    align-items: center;
}

.replied-message-content .MuiTypography-root {
    margin-left: 10px;
}

/* Close icon */
.replied-message-content .MuiIconButton-root {
    margin-left: auto;
    color: #757575;
    /* Gray color for the close icon */
}

.extensionRequestHistoryContainer {
    max-height: 400px;
    overflow-y: scroll;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.fundReleaseCard .custom-primary {
    color: #333;
    /* Dark color for label */
    font-weight: bold;
    /* You can adjust the font weight as needed */
}

.fundReleaseCard .custom-secondary {
    color: #666;
    /* Light color for value */
}

// Request History card
.approval-pipeline {
    display: flex;
    flex-direction: column;
    gap: '0px'
}

.approval-card {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
}

.left-line {
    width: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.approval-content {
    padding: 1rem;
    padding-left: 15px;
    /* Adjust padding to leave space for the left line */
    flex: 1;
    margin-left: 10px;
    /* Adjust margin to leave space for the left line */
}

.approval-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.avatar-section {
    display: flex;
    align-items: center;
}

.avatar-section h3 {
    margin-left: 1rem;
}

.status {
    font-weight: bold;
}

.approval-details p {
    margin: 0.25rem 0;
}

.information p{
    margin: unset;
}