.MuiContainer-root:has(> .masterParentClass) {
    padding: unset;
}


/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: white;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.masterParentClass {
    padding-bottom: 15px;
}

.stepperButton svg {
    height: 40px;
    width: 40px;
}

// .autoCompleteClass {
//     padding-top: 20px;
// }
.Pictures {
    margin: 10px;
}

// img {
//     margin: 5px;
// }

// star rating component
.MuiRating-icon {
    color: #2196f3;
}

.ratingFieldHeadline {
    font-size: 16px;
    font-weight: 400;
}

.overlay {
    /* ... your existing overlay styles ... */
    position: relative;
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
}

.overlay::before {
    content: "";
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    z-index: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.disabledButton {
    pointer-events: none;
    opacity: 0.1;
}

.workflowContainer .fldspacing {
    padding-bottom: 50px !important;
    max-height: 90px !important;
}


// .fldspacing {
//     max-height: 80px;
// }

.text.fldspacing {
    max-height: 90px !important;
    padding-bottom: 50px !important;
}

.minMaxContainer .text.fldspacing {
    /* Reset styles or set different styles if needed */
    max-height: initial !important;
    padding-bottom: initial !important;
}

.file.fldspacing .MuiFormControl-root {
    margin: unset !important;
}

.datePick.fldspacing {
    position: relative;
    bottom: 19px;
    max-height: 90px !important;
}

.userBasicDetailsContainer .fldspacing {
    padding-bottom: 50px !important;
    max-height: 90px !important;
}

.autoCompleteClass.fldspacing {
    padding-bottom: 50px !important;
    max-height: 90px !important;
}

.autoCompleteClass.multiSelect {
    padding-bottom: unset !important;
    min-height: 90px !important;
}


.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
.richTextEditor .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.richTextEditor .ql-editor {
    height: 200px !important;
    overflow-y: scroll;
}

.richTextEditor .information .ql-editor {
    height: 300px !important;
    overflow-y: scroll;
    scrollbar-width: thin;
}


.milestone-event {
    background-color: #329165;
    border: 1px solid #3291657d;
    border-radius: 4px;
    color: #ffffff;
    padding: 5px;
}
.meeting-event {
    background-color: #e82c30;
    border: 1px solid #e82c2f80;
    border-radius: 4px;
    color: #ffffff;
    padding: 5px;
}

.infrastructure-event {
    background-color: #6c757d;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    color: #ffffff;
    padding: 5px;
}

.timeline-event {
    background-color: #f1ae00;
    border: 1px solid #f1ad0085;
    border-radius: 4px;
    color: #ffffff;
    padding: 5px;
}

.deliverables-event {
    background-color: #fd7e14;
    border: 1px solid #e67300;
    border-radius: 4px;
    color: #ffffff;
    padding: 5px;
}

.custom-event-line {
    display: flex;
    font-size: 12px;
    align-items: center;
    font-weight: 100;
    justify-content: center;
    height: 100%; /* Ensure the line takes full height */
}

.custom-event-line {
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden;    /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds ellipsis (...) at the end of the text */
    width: 100%; /* Make sure it takes the full width of the container */
    display: block; /* Ensures it behaves as a block element */
}